/* eslint-disable react/jsx-props-no-spreading */
import { Body2, Body3, styled } from "@iventis/styles";
import React, { FunctionComponent } from "react";
import { Theme } from "@emotion/react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { FileUploadComponent, FileUploadComponentProps } from "./upload-file";

export interface UploadFileWithTextProps extends FileUploadComponentProps {
    topText: string;
    fileExtensions: string[];
}

export const UploadFileWithText: FunctionComponent<UploadFileWithTextProps> = ({
    topText,
    fileExtensions,
    fileSizeRestriction,
    onFileUploadTooLarge,
    ...fileUploadComponentProps
}) => {
    const translate = useIventisTranslate();
    return (
        <>
            <Body2>{topText}</Body2>
            <FileUploadComponent fileSizeRestriction={fileSizeRestriction} onFileUploadTooLarge={onFileUploadTooLarge} {...fileUploadComponentProps} />
            <BottomText className="supported-files">
                {translate(Content.map7.importGeoJson.reuploadGeoJson, { fileExtensions: fileExtensions.join(", "), size: `${fileSizeRestriction} MB` })}
            </BottomText>
        </>
    );
};

const BottomText = styled(Body3)`
    color: ${({ theme }: { theme: Theme }) => theme.shades.two};
`;
