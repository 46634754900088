import { Content } from "@iventis/translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CursorTooltip, DisabledOverlay, LoadingComponent } from "@iventis/components";
import { ErrorLarge } from "@iventis/components/src/error-large";
import {
    InteractiveElement,
    borderRadius,
    onHoverCornerButtonCss,
    skeletonShimmer,
    Header6,
    onHoverCornerButtonParentCss,
    StyledInteractiveArea,
    fontSizes,
    styled,
} from "@iventis/styles";
import { Theme, css } from "@emotion/react";
import React, { useMemo, useRef, useState } from "react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { useFunctionality } from "@iventis/utilities";
import { TEMPLATE_3D_TAG } from "./layer-template-query";

export const LAYER_TEMPLATE_HEIGHT_PX = 124;
export const LAYER_TEMPLATE_WIDTH_PX = 200;
export const LAYER_TEMPLATE_BORDERS_PX = 2;

export const LayerTemplatePreview: React.FC<{
    name: string;
    tags: string[];
    thumbnailUrl: string;
    iconUrl: string;
    selected: boolean;
    onClick: () => void;
    onEditClick?: () => void;
    loading: boolean;
    error: boolean;
    allowEdit?: boolean;
    dataCy?: string;
    testId?: string;
}> = ({ thumbnailUrl, iconUrl, selected, onClick, onEditClick, loading, error, name, tags, allowEdit = false, testId, dataCy }) => {
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const translate = useIventisTranslate();
    const nameContainerRef = useRef<HTMLHeadingElement>();

    const textInOverflow = useMemo(() => nameContainerRef.current?.scrollWidth > nameContainerRef.current?.offsetWidth, [loading, error, nameContainerRef.current]);

    const functionality = useFunctionality();

    const limit3dModel = !functionality.layer3DTypes && tags?.includes(TEMPLATE_3D_TAG);

    const disabled = limit3dModel;

    return (
        <Container selected={selected} onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)} data-cy={dataCy} data-testid={testId} $disabled={disabled}>
            {!disabled && <StyledInteractiveArea onClick={() => !loading && !error && onClick()} disabled={disabled} />}
            {loading && <LoadingComponent />}
            {error && <StyledLargeError text={translate(Content.map3.layerTemplates.failedToLoadLayer)} subdued />}
            {disabled && <DisabledOverlay />}
            {!loading && !error && (
                <CursorTooltip text={name} disabled={!textInOverflow || disabled}>
                    <div style={{ width: "100%", height: "100%" }}>
                        <LayerThumbnail className="template-image" imageUrl={thumbnailUrl} />
                        <div className="template-name">
                            {iconUrl && <IconImage src={iconUrl} />}
                            {!iconUrl && (
                                <span style={{ aspectRatio: "1", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <FontAwesomeIcon icon={["far", "layer-group"]} />
                                </span>
                            )}
                            <Header6 ref={nameContainerRef}>{name}</Header6>
                            {limit3dModel && (
                                <InteractiveElement className="upgrade-button" onClick={() => functionality.open?.("layer3DTypes")}>
                                    <FontAwesomeIcon icon={["far", "circle-up"]} className="upgrade-icon" />
                                </InteractiveElement>
                            )}
                        </div>
                    </div>
                </CursorTooltip>
            )}
            {allowEdit && mouseOver && (
                <InteractiveElement className="edit-button" onClick={onEditClick}>
                    <FontAwesomeIcon icon={["fas", "pen-to-square"]} />
                </InteractiveElement>
            )}
        </Container>
    );
};

const StyledLargeError = styled(ErrorLarge)`
    width: 100%;
    height: 100%;
    transform: scale(0.8);
`;

const CommonCSS = css`
    display: block;
    height: ${LAYER_TEMPLATE_HEIGHT_PX}px;
    width: ${LAYER_TEMPLATE_WIDTH_PX}px;
    border-radius: ${borderRadius.input};
    border: ${LAYER_TEMPLATE_BORDERS_PX}px solid #00000000;
    box-shadow: #00000014 0 1px 1px 1px;

    .template-image {
        height: 84px;
    }

    .template-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        width: 100%;

        .style-preview-icon {
            height: 80%;
            padding-left: 4px;
            flex-shrink: 0;
        }

        h6 {
            flex: 1 1;
            margin-left: 7px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: start;
        }
    }
`;

const SkeletonContainer = styled.div<{ sequence: number; totalElementCount: number }>`
    ${CommonCSS}
    border-color: transparent;
    opacity: ${({ sequence, totalElementCount }) => 1 - sequence / (totalElementCount - 1)};
`;

export const LayerTemplatePreviewSkeleton: React.FC<{ sequence: number; totalElementCount: number }> = ({ sequence, totalElementCount }) => (
    <SkeletonContainer sequence={sequence} totalElementCount={totalElementCount}>
        <SkeletonImage sequence={sequence} />
        <SkeletonCaption>
            <SkeletonCircle sequence={sequence} />
            <SkeletonPlaceholder sequence={sequence} />
        </SkeletonCaption>
    </SkeletonContainer>
);

const Container = styled.div<{ selected: boolean; $disabled: boolean }>`
    ${CommonCSS};
    width: ${LAYER_TEMPLATE_WIDTH_PX}px;
    height: ${LAYER_TEMPLATE_HEIGHT_PX}px;
    overflow: hidden;
    position: relative;

    :hover {
        border: ${LAYER_TEMPLATE_BORDERS_PX}px solid ${(props: { theme: Theme; $disabled: boolean }) => (props.$disabled ? "transparent" : props.theme.tertiaryColors.primary)};
        .edit-button {
            ${onHoverCornerButtonParentCss};
        }
    }
    .edit-button {
        ${onHoverCornerButtonCss};
    }

    ${({ selected, theme }) =>
        selected &&
        css`
            &,
            :hover {
                border: ${LAYER_TEMPLATE_BORDERS_PX}px solid ${theme.primaryColors.focus};
                .template-name {
                    background-color: ${theme.tertiaryColors.primaryBackground};
                }
            }
        `};

    .upgrade-button {
        position: relative;
        width: 40px;
        height: 40px;
        z-index: 1000;
        .upgrade-icon {
            font-size: ${fontSizes.medium};
            color: ${(props: { theme: Theme }) => props.theme.primaryColors.focus};
        }
    }
`;

const LayerThumbnail = styled.div<{ imageUrl: string }>`
    background-image: url(${(props) => props.imageUrl});
    background-size: cover;
`;

const IconImage = styled.img`
    height: 100%;
`;

const SkeletonPlaceholder = styled.span<{ sequence: number }>`
    ${({ sequence }) => skeletonShimmer({ sequence })};
    transform: scale(1, 0.9);
    overflow: hidden;
    width: 80%;
    height: 100%;
    margin: 5%;
    display: block;
`;

const SkeletonImage = styled.span<{ sequence: number }>`
    ${({ sequence }) => skeletonShimmer({ sequence })};
    width: 100%;
    height: 75%;
    display: block;
`;

const SkeletonCircle = styled.span<{ sequence: number }>`
    ${({ sequence }) => skeletonShimmer({ sequence })};
    width: 10%;
    height: 100%;
    display: block;
    margin: 5%;
`;

const SkeletonCaption = styled.div`
    display: flex;
    width: 100%;
    height: 10%;
`;
