import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import React, { FunctionComponent } from "react";
import { Content } from "@iventis/translations/content/typed-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@iventis/styles";
import { AreaDimension } from "@iventis/domain-model/model/areaDimension";
import { getDefaultStyleProperty } from "@iventis/map-engine/src/utilities/style-helpers";
import { getStaticStyleValue } from "@iventis/layer-style-helpers";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import {
    colourSelectorComponentCreator,
    ColourSelectorFormulaPreview,
    definedValueSelectorComponentCreator,
    incrementalValueSelectorComponentCreator,
    selectItemsComponentCreator,
    sliderWithTextSelectorCreator,
} from "@iventis/components";
import { StyledStyleItemContainer } from "./line-edit-style";
import EditTextStyle from "./text-edit-style";
import EditOutlineStyle from "./outline-edit-style";
import { EditStyleItemComponent } from "./edit-style-item";
import StyleContainer from "./style-container";
import { EditStyleProps } from "./style.types";

export const AreaEditStyleComponent: FunctionComponent<EditStyleProps<AreaStyle>> = ({ layerStyle, dataFields, changeStyleValue }) => {
    const translate = useIventisTranslate();

    const is3dFill = getStaticStyleValue(layerStyle.dimension) === AreaDimension.Three;

    return (
        <>
            <StyleContainer title={translate(Content.map2.styles.type_of_area)}>
                <StyledStyleItemContainer>
                    {/* TYPE OF AREA */}
                    <EditStyleItemComponent
                        styleProperty="dimension"
                        component={selectItemsComponentCreator({
                            testId: "style-type-selector",
                            options: [
                                { id: AreaDimension.Two, name: translate(Content.map2.styles.area_2d) },
                                { id: AreaDimension.Three, name: translate(Content.map2.styles.area_3d) },
                            ],
                        })}
                        value={layerStyle.dimension}
                        changeStyleValue={changeStyleValue}
                        ignoreTitle
                    />
                </StyledStyleItemContainer>
            </StyleContainer>
            <StyleContainer title={translate(Content.map2.styles.area_fill)} icon={<EditStyleHeaderIcon id="header-icon" icon={["fas", "diamond"]} />}>
                <StyledStyleItemContainer>
                    {/* ENABLE FILL */}
                    {!is3dFill && (
                        <EditStyleItemComponent
                            styleProperty="fill"
                            component={definedValueSelectorComponentCreator<boolean>({
                                optionalValues: [
                                    { value: true, label: translate(Content.map4.styles.fill) },
                                    { value: false, label: translate(Content.common.none) },
                                ],
                            })}
                            value={layerStyle.fill ?? getDefaultStyleProperty(layerStyle.styleType, "fill")}
                            changeStyleValue={changeStyleValue}
                            title={translate(Content.map4.styles.fill)}
                        />
                    )}
                    {/* FILL COLOUR */}
                    <EditStyleItemComponent
                        styleProperty="colour"
                        component={colourSelectorComponentCreator()}
                        PreviewComponent={ColourSelectorFormulaPreview}
                        showDataDriven
                        title={translate(Content.map2.styles.colour)}
                        value={layerStyle.colour || getDefaultStyleProperty(layerStyle.styleType, "colour")}
                        changeStyleValue={changeStyleValue}
                        dataFields={dataFields}
                        disabled={!getStaticStyleValue(layerStyle.fill)}
                    />
                    {/* OPACITY */}
                    <EditStyleItemComponent
                        styleProperty="opacity"
                        value={layerStyle.opacity || getDefaultStyleProperty(layerStyle.styleType, "opacity")}
                        component={sliderWithTextSelectorCreator({
                            minValue: 0,
                            maxValue: 1,
                            increment: 0.05,
                            colour: layerStyle.colour.staticValue.staticValue,
                            testId: "opacity-selector",
                        })}
                        changeStyleValue={changeStyleValue}
                        title={translate(Content.map2.styles.opacity)}
                        isZoomableValue={false}
                        disabled={!getStaticStyleValue(layerStyle.fill)}
                    />
                    {is3dFill && (
                        <EditStyleItemComponent
                            styleProperty="height"
                            component={incrementalValueSelectorComponentCreator({
                                maxValue: 1000,
                                minValue: 1,
                                increment: 1,
                                units: [{ id: "m", name: translate(Content.map3.units.meters) }],
                                selectedUnitId: "m",
                                decimals: 2,
                            })}
                            value={layerStyle.height ?? getDefaultStyleProperty(layerStyle.styleType, "height")}
                            changeStyleValue={changeStyleValue}
                            title={translate(Content.map2.styles.height)}
                            isZoomableValue={false}
                            disabled={!getStaticStyleValue(layerStyle.fill)}
                        />
                    )}
                </StyledStyleItemContainer>
            </StyleContainer>
            {getStaticStyleValue(layerStyle.dimension) === AreaDimension.Two && (
                <>
                    <EditOutlineStyle layerStyle={layerStyle} changeStyleValue={changeStyleValue} />
                    <EditTextStyle layerStyle={layerStyle} changeStyleValue={changeStyleValue} dataFields={dataFields} />
                </>
            )}
        </>
    );
};

export const EditStyleHeaderIcon = styled(FontAwesomeIcon)`
    height: 12px;
    width: 12px;
`;
