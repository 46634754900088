import { Body2, formGap, styled } from "@iventis/styles";
import { Divider } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { UploadFileWithText, UploadFileWithTextProps } from "./upload-file-with-text";

export type AspectRatio = { width: number; height: number };

interface UploadImageWithPreviewProps
    extends Pick<UploadFileWithTextProps, "fileExtensions" | "uploadButtonText" | "topText" | "inputAccept" | "fileSizeRestriction" | "onFileUploadTooLarge"> {
    imageName: string;
    imageUrl: string;
    containerDataTestId?: string;
    onTempImageUpload: (file: File) => void;
    aspectRatioLimit?: AspectRatio;
}

export const UploadImageWithPreview: FunctionComponent<UploadImageWithPreviewProps> = ({
    imageUrl,
    imageName,
    fileExtensions,
    uploadButtonText,
    topText,
    inputAccept,
    fileSizeRestriction,
    containerDataTestId,
    onFileUploadTooLarge,
    onTempImageUpload,
    aspectRatioLimit,
}) => {
    const translate = useIventisTranslate();

    const [tempImage, setTempImage] = useState<null | { image: string; name: string }>(null);
    const [errorMessage, setErrorMessage] = useState<null | "aspect_ratio_diff">(null);

    const handleFileUpload = (file: File) => {
        onTempImageUpload(file);
        const imageURL = URL.createObjectURL(file);
        setTempImage({ image: imageURL, name: file.name });
    };

    return (
        <>
            <UploadFileWithTextContainer data-testid={containerDataTestId}>
                <UploadFileWithText
                    topText={topText}
                    fileExtensions={fileExtensions}
                    fileSizeRestriction={fileSizeRestriction}
                    uploadFile={handleFileUpload}
                    uploadButtonText={uploadButtonText}
                    loading={false}
                    fileName={tempImage == null ? imageName : tempImage.name}
                    persistFileSelectInput
                    inputAccept={inputAccept}
                    onFileUploadTooLarge={onFileUploadTooLarge}
                />
            </UploadFileWithTextContainer>
            <WarningText>
                {errorMessage === "aspect_ratio_diff" ? (
                    <span>{translate(Content.map8.styles.customImageAspectRatio, { aspectRatio: `${aspectRatioLimit.width}:${aspectRatioLimit.height}` })}</span>
                ) : (
                    ""
                )}
            </WarningText>
            <StyledDivider />
            <ImagePreview
                src={tempImage == null ? imageUrl : tempImage.image}
                alt={tempImage == null ? imageName : tempImage.name}
                onLoad={(event) => {
                    const image = event.target as HTMLImageElement;
                    if (image && aspectRatioLimit) {
                        const { width, height } = image;
                        // Check if the aspect ratio of the image matches the aspect ratio limit
                        // Tolerance of 0.01
                        const imageAspectRatio = width / height;
                        const aspectRatioLimitValue = aspectRatioLimit.width / aspectRatioLimit.height;
                        if (Math.abs(imageAspectRatio - aspectRatioLimitValue) > 0.01) {
                            setErrorMessage("aspect_ratio_diff");
                        } else {
                            setErrorMessage(null);
                        }
                    }
                }}
            />
        </>
    );
};

const UploadFileWithTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${formGap};
`;

const StyledDivider = styled(Divider)`
    margin: 20px 0px;
`;

const ImagePreview = styled.img`
    max-width: 60vw;
    max-height: 50vh;
    object-fit: contain;
`;

const WarningText = styled(Body2)`
    color: ${({ theme }) => theme.toastColours.warning};
    margin-top: ${formGap};
`;
