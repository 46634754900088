import { AssetType } from "@iventis/domain-model/model/assetType";
import { Asset } from "@iventis/domain-model/model/asset";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import React, { FunctionComponent, useState, useMemo, useRef } from "react";
import { styled, formGap } from "@iventis/styles";
import { Model } from "@iventis/domain-model/model/model";
import { useDebounce, getModelQueryKey } from "@iventis/utilities";
import { QueryClient, useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { SearchBarComponent } from "@iventis/search/components/search-bar";

import { Theme } from "@emotion/react";
import { FilterFormat } from "@iventis/types/api.types";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { Status } from "@iventis/domain-model/model/status";
import { Hierarchy } from "@iventis/domain-model/model/hierarchy";
import { AssetCategory } from "@iventis/domain-model/model/assetCategory";
import { AssetBrowserRef, AssetBrowserComponent } from "../asset-browser";
import { LoadingComponent } from "../loading";
import { FormWizardTemplate } from "../form-wizard-template";
import { AssetCategoryChips } from "./asset-categories";
import { UploadIconData, UploadIconForm } from "../upload-icon";
import { UploadAssetModal, UploadModelData, UploadModelForm } from "../upload-model";
import { convertModelToUploadModelData } from "./asset-helpers";
import { AssetActionsComponent } from "./asset-actions";

const getAssetsQueryKey = "assets";

export const getDefaultAssetByType = async (
    getAssets: (pageNumber: number, assetType: AssetType, filter: FilterFormat[]) => Promise<PagedResult<Asset[]>>,
    assetType: AssetType,
    queryClient: QueryClient
) => {
    const results = await getAssets(1, assetType, []);
    queryClient.setQueryData([getAssetsQueryKey, assetType, []], { pages: [results] });
    return results.results[0].id;
};

export const getDefaultModelId = async (getModels: () => Promise<Model[]>) => {
    const models = await getModels();
    return models[0]?.id;
};

export const AssetPickerComponent: FunctionComponent<{
    onAssetSelected: (asset: Asset, assetType: AssetType) => void;
    assetType: AssetType;
    selectorDescription: string;
    close: () => void;
    currentAssetId: string;
    hideAssetActions: boolean;
    imageUrlGetter: (asset: Asset) => string;
    getAsset: (assetId: string) => Promise<Asset>;
    getAssetTags: (tagType: AssetType) => Promise<Hierarchy<AssetCategory>[]>;
    getAssetsByType: (pageNumber: number, assetType: AssetType, filter: FilterFormat[]) => Promise<PagedResult<Asset[]>>;
    postIcon: (data: UploadIconData) => Promise<Asset>;
    patchIcon: (data: UploadIconData, existingAsset: Asset) => Promise<Asset>;
    postModel: (data: UploadModelData) => Promise<Model & { thumbnail: Asset }>;
    updateModel?: (updated: UploadModelData, original: UploadModelData) => Promise<Model>;
    getModel?: (id: string) => Promise<Model>;
    canEditAsset: (asset: Asset) => boolean;
    suppressInfiniteFetching?: boolean;
    deleteAsset: (assetId: string) => Promise<void>;
    customImageOnModelValidator?: (modelFile: File) => Promise<boolean>;
}> = ({
    onAssetSelected,
    assetType,
    hideAssetActions,
    currentAssetId,
    close,
    imageUrlGetter,
    getAssetTags,
    getAssetsByType,
    suppressInfiniteFetching = false,
    postIcon,
    patchIcon,
    postModel,
    canEditAsset,
    getAsset,
    deleteAsset,
    getModel,
    updateModel,
    customImageOnModelValidator,
}) => {
    // Display text
    const translate = useIventisTranslate();
    const translatedAssetType = translate(Content.map3.assets[assetType]);
    const allCategoriesText = translate(Content.map3.assets.all_categories);

    const [modal, setModal] = useState<null | "upload" | "edit" | "delete">(null);
    const [selectedAssetId, setSelectedAssetId] = useState<string>();

    // Search
    const { value: search, debouncedValue: debouncedSearch, setValue: setSearch } = useDebounce("", 400);

    const [selectedCategory, setSelectedCategory] = useState<Hierarchy<AssetCategory> | undefined>(undefined);

    const queryClient = useQueryClient();

    const handlePostModel = async (model: UploadModelData) => {
        const res = await postModel(model);
        return handleModelResponse(res);
    };

    const handleUpdateModel = async (updated: UploadModelData, original: UploadModelData) => {
        const res = await updateModel(updated, original);
        return handleModelResponse(res);
    };

    const handleModelResponse = (model: Model) => {
        const models = queryClient.getQueryData<Model[]>([getModelQueryKey]);
        const updatedModels = [...models, model];
        queryClient.setQueryData<Model[]>([getModelQueryKey], updatedModels);
        return model;
    };

    // Combined filter
    const filter: FilterFormat[] = useMemo(
        () => [
            ...(debouncedSearch.length > 0 ? [{ fieldName: "search", operator: IventisFilterOperator.Eq, value: debouncedSearch }] : []),
            ...(selectedCategory != null ? [{ fieldName: "tags", operator: IventisFilterOperator.In, value: [selectedCategory?.item?.name] }] : []),
        ],
        [debouncedSearch, selectedCategory]
    );

    // Assets query
    const { data, isLoading, error, fetchNextPage, isFetchingNextPage, refetch, remove } = useInfiniteQuery(
        [getAssetsQueryKey, assetType, filter],
        async ({ pageParam }) => {
            const response = await getAssetsByType(pageParam?.pageNumber ?? 1, assetType, filter);
            return response;
        },
        {
            keepPreviousData: true,
            getNextPageParam: (prevPage, allPages) => (prevPage.lastPage ? undefined : { pageNumber: (allPages?.length ?? 0) + 1 }),
        }
    );
    const assets = useMemo(() => data?.pages?.filter((x) => x[0] === undefined).flatMap((page) => page.results), [data]);

    // Other
    const assetBrowserRef = useRef<AssetBrowserRef>(null);

    const [selection, setSelection] = useState<string[]>([]);

    const selectedAssets = useMemo(() => assets?.filter((asset) => selection.includes(asset.id)), [assets, selection]);

    const { data: iconCategories } = useQuery(["categories", AssetType.MapIcon], async () => getAssetTags(AssetType.MapIcon));
    const { data: modelCategories } = useQuery(["categories", AssetType.Model], async () => getAssetTags(AssetType.Model));

    const showAssetUpload = async (showModal: boolean) => {
        if (showModal) return setModal("upload");
        remove();
        await refetch();
        return setModal(null);
    };

    const [editing, setEditing] = useState<string>(null);
    const onEditClicked = (assetId: string) => {
        setEditing(assetId);
        setModal("edit");
    };

    const getExistingModelData = async (id: string) => {
        const model = await getModel(id);
        const asset = assets.find(({ id }) => editing === id);
        const imageUrl = imageUrlGetter(asset);
        return convertModelToUploadModelData({ ...model, thumbnail: asset, categories: asset.tags }, imageUrl);
    };

    const handleDelete = async (assetId: string) => {
        await deleteAsset(assetId);
        await refetch();
    };

    return (
        <>
            {modal !== "upload" && (
                <FormWizardTemplate
                    stages={[
                        {
                            isValid: selection?.length > 0 && selectedAssets?.every((asset) => asset.status === Status.Active),
                            primaryButtonText: translate(Content.common.buttons.confirm),
                            primaryButtonCallback: () => {
                                const selection = assets.find((asset) => asset.id === assetBrowserRef.current.getSelection()[0]);
                                if (selection !== undefined) {
                                    onAssetSelected(selection, assetType);
                                }
                            },
                            secondaryButtons: [{ buttonText: translate(Content.common.buttons.cancel), onButtonPressed: close }],
                            submitButtonDataCy: "confirm-asset-button",
                        },
                    ]}
                    currentStage={0}
                    title={translate(Content.map3.assets.select, { assetType: translatedAssetType })}
                >
                    <StyledContainer>
                        <SearchBarComponent
                            value={search}
                            onValueChange={setSearch}
                            placeHolderText={translate(Content.map3.assets.search_placeholder, { assetType: translatedAssetType })}
                            className="search-bar"
                        />
                        <AssetCategoryChips
                            onCategorySelected={setSelectedCategory}
                            selectedCategory={selectedCategory}
                            allCategoriesText={allCategoriesText}
                            getTags={getAssetTags}
                            assetType={assetType}
                        />
                        <AssetActionsComponent
                            selectedAsset={selectedAssets?.[0]}
                            deleteAsset={handleDelete}
                            editAsset={onEditClicked}
                            uploadAsset={() => showAssetUpload(true)}
                            canEditAsset={canEditAsset}
                            hideActions={hideAssetActions}
                        />
                        <div className="box">
                            {isLoading ? (
                                <LoadingComponent />
                            ) : error ? (
                                <span>Error</span>
                            ) : (
                                <AssetBrowserComponent
                                    ref={assetBrowserRef}
                                    imageUrlGetter={imageUrlGetter}
                                    assets={assets}
                                    initialSelection={selectedAssetId || currentAssetId}
                                    fetchNextPage={() => !suppressInfiniteFetching && fetchNextPage()}
                                    isFetchingNextPage={isFetchingNextPage}
                                    multiSelect={false}
                                    cardWidth="50px"
                                    cardHeight="50px"
                                    cardMinWidth="60px"
                                    className="asset-browser"
                                    onSelectionChange={setSelection}
                                />
                            )}
                        </div>
                    </StyledContainer>
                </FormWizardTemplate>
            )}
            {modal === "upload" && (
                <UploadAssetModal open close={() => showAssetUpload(false)} minModalDimensions={{ width: "450px", height: "80vh" }}>
                    {assetType === AssetType.MapIcon && (
                        <UploadIconForm
                            close={() => showAssetUpload(false)}
                            categories={iconCategories?.map((cat) => cat.item?.name)}
                            uploadRemotely={postIcon}
                            setSelectedId={setSelectedAssetId}
                        />
                    )}
                    {assetType === AssetType.Model && (
                        <UploadModelForm
                            close={() => showAssetUpload(false)}
                            categories={modelCategories?.map((cat) => cat.item?.name)}
                            customImageOnModelValidator={customImageOnModelValidator}
                            uploadRemotely={handlePostModel}
                            setSelectedId={setSelectedAssetId}
                        />
                    )}
                </UploadAssetModal>
            )}
            {modal === "edit" && (
                <UploadAssetModal open close={() => showAssetUpload(false)} minModalDimensions={{ width: "450px", height: "80vh" }}>
                    {assetType === AssetType.MapIcon && (
                        <UploadIconForm
                            close={() => showAssetUpload(false)}
                            categories={iconCategories?.map((cat) => cat.item.name)}
                            getExistingData={async (id) => {
                                const asset = await getAsset(id);
                                return {
                                    categories: asset.tags,
                                    image: { fileName: asset.name, imageUrl: imageUrlGetter(asset) },
                                    name: asset.name,
                                    metaData: asset.metaData,
                                };
                            }}
                            existingId={editing}
                            uploadRemotely={(data) =>
                                patchIcon(
                                    data,
                                    assets.find((x) => editing === x.id)
                                )
                            }
                            setSelectedId={setSelectedAssetId}
                        />
                    )}
                    {assetType === AssetType.Model && (
                        <UploadModelForm
                            close={() => showAssetUpload(false)}
                            categories={modelCategories?.map((cat) => cat.item.name)}
                            uploadRemotely={handleUpdateModel}
                            setSelectedId={setSelectedAssetId}
                            getExistingModel={getExistingModelData}
                            existingId={editing}
                            customImageOnModelValidator={customImageOnModelValidator}
                            translate={translate}
                        />
                    )}
                </UploadAssetModal>
            )}
        </>
    );
};

const StyledContainer = styled.div`
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    gap: ${formGap};
    min-height: 0;
    .search-bar {
        width: 100%;
    }
    .box {
        position: relative;
        overflow-y: auto;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .asset-browser {
            flex-grow: 1;
        }
    }
    .upload-icon-link {
        text-align: left;
        text-decoration: underline;
    }
    .upgrade-required {
        color: ${(props: { theme: Theme }) => props.theme.primaryColors.focus};
    }
`;
