import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { InfoTooltipHoverComponent, MultiSelectDropdownComponent } from "@iventis/components";
import { Body2, Body3, styled, StyledFieldLabel } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import React, { useEffect } from "react";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { DataField } from "@iventis/domain-model/model/dataField";
import { useQuery } from "@tanstack/react-query";
import { getMapObjectNameSystemDatafield } from "@iventis/datafields";
import { useEditLayerServices } from "./edit-layer-services";

interface EditLayerMapObjectToolTipEditorProps {
    layer: MapLayer;
    disabled: boolean;
    setDataFields: (dataFieldIds: MapLayer["tooltipDataFieldIds"]) => void;
}

const ALLOWED_DATA_FIELD_TYPES = [DataFieldType.Text, DataFieldType.Number, DataFieldType.List, DataFieldType.What3Words];

export function EditLayerMapObjectToolTipEditorComponent({ layer, disabled, setDataFields }: EditLayerMapObjectToolTipEditorProps) {
    const translate = useIventisTranslate();
    const { styleService } = useEditLayerServices();
    // Get the map object data field
    const { data: mapObjectDataField } = useQuery(["map-object-datafield"], async () => {
        const projectDataFields = await styleService.getProjectDataFields();
        return getMapObjectNameSystemDatafield(projectDataFields);
    });

    const onSelectedDataFieldsChanged = (selectedDataFields: DataField[]) => {
        setDataFields(selectedDataFields.map(({ id }) => id));
    };

    // Get all valid datafields
    const allowedLayerDataFields = layer.dataFields.filter((dataField) => ALLOWED_DATA_FIELD_TYPES.includes(dataField.type));

    // Get the selected data fields
    const selectedLayerDataFields = layer.dataFields.filter((dataField) => (layer.tooltipDataFieldIds ?? []).includes(dataField.id));

    // If there are no other selected datafields, do not allow the map object data field to be deselected
    const disabledValues = layer.tooltipDataFieldIds?.length === 1 && layer.tooltipDataFieldIds[0] === mapObjectDataField?.id ? [mapObjectDataField.id] : [];

    // If there are no selected data fields, select the map object data field
    useEffect(() => {
        if (selectedLayerDataFields.length === 0 && mapObjectDataField != null) {
            setDataFields([mapObjectDataField.id]);
        }
    }, [mapObjectDataField, selectedLayerDataFields]);

    return (
        <div>
            <EditLayerObjectHoverLabelHeader>
                <StyledFieldLabel>{translate(Content.map9.dataFieldsInTooltip.title)}</StyledFieldLabel>
                <InfoTooltipHoverComponent
                    placement="right"
                    component={
                        <Tooltip>
                            <Body2>{translate(Content.map9.dataFieldsInTooltip.tooltip)}</Body2>
                            <Body3 className="tooltip-sub-text">{translate(Content.map9.dataFieldsInTooltip.tooltipAttributeTypes)}</Body3>
                        </Tooltip>
                    }
                />
            </EditLayerObjectHoverLabelHeader>
            <MultiSelectDropdownComponent
                values={allowedLayerDataFields}
                selectedValues={selectedLayerDataFields}
                disabledValues={disabledValues}
                onValuesUpdate={onSelectedDataFieldsChanged}
                maximumValueSelected={5}
                selectedValuesJoiningString=";"
                placeHolderText={translate(Content.common.none)}
                disabled={disabled}
                dataTestId="map-object-tooltip-selector"
            />
        </div>
    );
}

const EditLayerObjectHoverLabelHeader = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    height: 40px;

    label {
        margin-bottom: 0px;
    }
`;

const Tooltip = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-weight: 400;
    padding: 10px;
    max-width: 250px;

    .tooltip-sub-text {
        font-style: italic;
        line-height: 20px;
    }
`;
