import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { DataField } from "@iventis/domain-model/model/dataField";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { getDefaultStyleProperty } from "@iventis/map-engine/src/utilities/style-helpers";
import { getStaticStyleValue } from "@iventis/layer-style-helpers";
import { createLiteralStyleValue } from "@iventis/map-engine/src/utilities/literal-styles";
import { Content } from "@iventis/translations/content/typed-content";
import React, { useContext, useMemo } from "react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { EMPTY_GUID } from "@iventis/utilities";
import { TextPosition } from "@iventis/domain-model/model/textPosition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colourSelectorComponentCreator, definedValueSelectorComponentCreator, incrementalValueSelectorComponentCreator, sliderWithTextSelectorCreator } from "@iventis/components";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { useQuery } from "@tanstack/react-query";
import { getProjectDataFieldsQueryKey } from "@iventis/datafield-editor";
import { getMapObjectNameSystemDatafield } from "@iventis/datafields";
import { EditStyleItemComponent } from "./edit-style-item";
import { EditStyleHeaderIcon } from "./area-edit-style";
import { ChangeStyleValue } from "./edit-style.helpers";
import { StyledStyleItemContainer } from "./line-edit-style";
import StyleContainer from "./style-container";
import { EditDataDrivenTextContent } from "./edit-data-driven-text-content";
import { EditStyleContext } from "./edit-style-context";
import { IconTextEditStyle } from "./icon-text-edit-style";

export type StylesWithText = AreaStyle | IconStyle | PointStyle | LineStyle;
interface EditTextStyleProps {
    layerStyle: StylesWithText;
    dataFields: DataField[];
    changeStyleValue: ChangeStyleValue<StylesWithText>;
}

const EditTextStyle = ({ layerStyle, dataFields, changeStyleValue }: EditTextStyleProps) => {
    const { getProjectDataFields } = useContext(EditStyleContext);
    const { data: projectDataFields } = useQuery([getProjectDataFieldsQueryKey], getProjectDataFields);
    const translate = useIventisTranslate();
    const mapObjectNameDatafield = useMemo(() => getMapObjectNameSystemDatafield(projectDataFields), [projectDataFields]);
    return (
        <StyleContainer title={translate(Content.map2.styles.text)} icon={<EditStyleHeaderIcon id="header-icon" icon={["fas", "text"]} />} defaultOpen={false} testId="text">
            <StyledStyleItemContainer>
                {/* TEXT TOGGLE */}
                <EditStyleItemComponent
                    styleProperty="text"
                    value={layerStyle.text ?? getDefaultStyleProperty(layerStyle.styleType, "text")}
                    component={definedValueSelectorComponentCreator<boolean>({
                        optionalValues: [
                            { value: true, label: translate(Content.map2.styles2.on) },
                            { value: false, label: translate(Content.map2.styles2.off) },
                        ],
                    })}
                    changeStyleValue={changeStyleValue}
                    title={translate(Content.map2.styles.text)}
                />

                {/* TEXT Content */}
                <EditDataDrivenTextContent
                    styleProperty="textContent"
                    value={layerStyle.textContent}
                    changeStyleValue={changeStyleValue}
                    dataFields={dataFields}
                    disabled={!getStaticStyleValue(layerStyle.text)}
                    defaultValue={createLiteralStyleValue(EMPTY_GUID, mapObjectNameDatafield?.id)}
                />

                {/* TEXT Colour */}
                <EditStyleItemComponent
                    styleProperty="textColour"
                    component={colourSelectorComponentCreator()}
                    title={translate(Content.map2.styles.text_colour)}
                    value={layerStyle.textColour ?? getDefaultStyleProperty(layerStyle.styleType, "textColour")}
                    changeStyleValue={changeStyleValue}
                    dataFields={dataFields}
                    disabled={!getStaticStyleValue(layerStyle.text)}
                />
                {/* TEXT SIZE */}
                <EditStyleItemComponent
                    styleProperty="textSize"
                    value={layerStyle.textSize ?? getDefaultStyleProperty(layerStyle.styleType, "textSize")}
                    changeStyleValue={changeStyleValue}
                    component={incrementalValueSelectorComponentCreator({
                        minValue: 0,
                        maxValue: 28,
                        increment: 2,
                        units: [{ id: "px", name: translate(Content.map3.units.pixels) }],
                        selectedUnitId: "px",
                        testId: "text-size",
                    })}
                    title={translate(Content.map2.styles.text_size)}
                    disabled={!getStaticStyleValue(layerStyle.text)}
                />
                {/* TEXT ALLOW OVERLAP */}
                <EditStyleItemComponent
                    styleProperty="textOverlap"
                    component={definedValueSelectorComponentCreator<boolean>({
                        optionalValues: [
                            { value: true, label: translate(Content.map2.styles.allow_text_overlap) },
                            { value: false, label: translate(Content.map2.styles.no_text_overlap) },
                        ],
                    })}
                    value={layerStyle.textOverlap ?? getDefaultStyleProperty(layerStyle.styleType, "textOverlap")}
                    changeStyleValue={changeStyleValue}
                    title={translate(Content.map2.styles.text_overlap)}
                    disabled={!getStaticStyleValue(layerStyle.text)}
                />
                {/* TEXT OUTLINE WIDTH */}
                <EditStyleItemComponent
                    styleProperty="textOutlineWidth"
                    value={layerStyle.textOutlineWidth ?? getDefaultStyleProperty(layerStyle.styleType, "textOutlineWidth")}
                    changeStyleValue={changeStyleValue}
                    component={incrementalValueSelectorComponentCreator({
                        minValue: 0,
                        maxValue: 5,
                        increment: 1,
                        units: [{ id: "px", name: translate(Content.map3.units.pixels) }],
                        selectedUnitId: "px",
                    })}
                    title={translate(Content.map2.styles.text_outline_width)}
                    isZoomableValue={false}
                    disabled={!getStaticStyleValue(layerStyle.text)}
                />
                {/* TEXT OUTLINE COLOUR */}
                <EditStyleItemComponent
                    styleProperty="textOutlineColour"
                    component={colourSelectorComponentCreator()}
                    title={translate(Content.map2.styles.text_outline_colour)}
                    value={layerStyle.textOutlineColour ?? getDefaultStyleProperty(layerStyle.styleType, "textOutlineColour")}
                    changeStyleValue={changeStyleValue}
                    dataFields={dataFields}
                    disabled={!getStaticStyleValue(layerStyle.text)}
                />
                {/* TEXT OPACITY */}
                <EditStyleItemComponent
                    styleProperty="textOpacity"
                    value={layerStyle.textOpacity ?? getDefaultStyleProperty(layerStyle.styleType, "textOpacity")}
                    component={sliderWithTextSelectorCreator({
                        minValue: 0,
                        maxValue: 1,
                        increment: 0.05,
                        colour: getStaticStyleValue(layerStyle.textColour) ?? getStaticStyleValue(getDefaultStyleProperty(layerStyle.styleType, "textColour")),
                    })}
                    changeStyleValue={changeStyleValue}
                    title={translate(Content.map2.styles.text_opacity)}
                    isZoomableValue={false}
                    disabled={!getStaticStyleValue(layerStyle.text)}
                />
                {/* TEXT WEIGHT */}
                <EditStyleItemComponent
                    styleProperty="textBold"
                    component={definedValueSelectorComponentCreator<boolean>({
                        optionalValues: [
                            { value: true, label: translate(Content.map2.styles2.bold) },
                            { value: false, label: translate(Content.map2.styles2.regular) },
                        ],
                    })}
                    value={layerStyle.textBold ?? getDefaultStyleProperty(layerStyle.styleType, "textBold")}
                    changeStyleValue={changeStyleValue}
                    title={translate(Content.map2.styles.text_weight)}
                    disabled={!getStaticStyleValue(layerStyle.text)}
                />
                {(layerStyle.styleType === StyleType.Point || layerStyle.styleType === StyleType.Icon) && (
                    <>
                        <EditStyleItemComponent
                            styleProperty="textPosition"
                            value={layerStyle.textPosition ?? getDefaultStyleProperty(layerStyle.styleType, "textPosition")}
                            component={definedValueSelectorComponentCreator<TextPosition>({
                                optionalValues: [
                                    { value: TextPosition.Automatic, icon: <FontAwesomeIcon icon={["far", "up-down-left-right"]} size="lg" /> },
                                    { value: TextPosition.Centre, icon: <FontAwesomeIcon icon={["far", "circle-dot"]} size="lg" /> },
                                    { value: TextPosition.Above, icon: <FontAwesomeIcon icon={["far", "up"]} size="lg" /> },
                                    { value: TextPosition.Below, icon: <FontAwesomeIcon icon={["far", "down"]} size="lg" /> },
                                    { value: TextPosition.Left, icon: <FontAwesomeIcon icon={["far", "left"]} size="lg" /> },
                                    { value: TextPosition.Right, icon: <FontAwesomeIcon icon={["far", "right"]} size="lg" /> },
                                ],
                            })}
                            changeStyleValue={changeStyleValue}
                            title={translate(Content.map4.edit_layer.text_position)}
                            disabled={!getStaticStyleValue(layerStyle.text)}
                        />
                        <EditStyleItemComponent
                            styleProperty="textOffset"
                            value={layerStyle.textOffset ?? getDefaultStyleProperty(layerStyle.styleType, "textOffset")}
                            changeStyleValue={changeStyleValue}
                            component={incrementalValueSelectorComponentCreator({
                                minValue: 0,
                                maxValue: 25,
                                increment: 1,
                                units: [{ id: "px", name: translate(Content.map3.units.pixels) }],
                                selectedUnitId: "px",
                            })}
                            title={translate(Content.map4.edit_layer.text_offset)}
                            isZoomableValue={false}
                            disabled={!getStaticStyleValue(layerStyle.text)}
                        />
                        {layerStyle.styleType === StyleType.Icon && <IconTextEditStyle changeStyleValue={changeStyleValue} layerStyle={layerStyle} />}
                    </>
                )}
            </StyledStyleItemContainer>
        </StyleContainer>
    );
};

export default EditTextStyle;
